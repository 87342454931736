<template>
  <article id="page-assistance">
    <commonbanner></commonbanner>
    <div class="container">
      <div class="row">
        <div class="interpretation p-4">
          <div class="evaluation">
            <div class="desc-box">
              <div class="desc-left">
                <div class="desc-title">
                  满意度评价 <span>机关履职评价</span>
                </div>
                <p>
                  为深入践行以人民为中心的发展思想，树立人民满意工作导向，客观反映人民群众对司法行政工作的满意程度，多渠道听取群众意见建议，XX省司法厅设立"满意度评价"栏目。
                </p>
                <p>请点击"我要评价"键，进行评价。</p>
              </div>
              <div class="desc-right">
                <img src="../../assets/images/evaluation.png" alt="" />
              </div>
            </div>
            <div class="evaluation-btn"><span>
              <router-link to="/evaluationcommit">
              我要评价
              </router-link></span></div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import Commonbanner from '@/components/base/Commonbanner'
export default {
  name: 'evaluation',
  methods: {
    getEvaluateForm () {
      this.$router.push({ path: '/commit' })
    }
  },
  components: {
    Commonbanner
  }
}
</script>
<style scoped src="../../assets/css/evaluation.css"></style>
